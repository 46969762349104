import React from "react";
import "../css/NavbarFooter.css";
import footerimg1 from "../images/img_01.jpg";
import footerimg2 from "../images/img_02.jpg";

const Footer = () => {
  return (
    <footer className="footer" data-background="assets/img/bg/footer-noise.png">
      <ul className="footer__social">
        <li>
          <a href="#!">
            <i className="fab fa-facebook-f"></i>Facebook
          </a>
        </li>
        <li>
          <a href="#!">
            <i className="fab fa-twitter"></i>Twitter
          </a>
        </li>
        <li>
          <a href="#!">
            <i className="fab fa-instagram"></i>Instagram
          </a>
        </li>
        <li>
          <a href="#!">
            <i className="fab fa-youtube"></i>Youtube
          </a>
        </li>
        <li>
          <a href="#!">
            <i className="fab fa-pinterest"></i>Pinterest
          </a>
        </li>
      </ul>
      <div className="container mxw_1350">
        <div className="row pt-65 pb-65">
          <div className="col-lg-3 col-md-6 footer__col mt-30 about">
            <div className="footer__widget">
              <h3>About Us</h3>
              <p>
                We’re the best Melbourne cleaning service to have a completely
                online booking platform. Simply jump to our pricing page, choose
                an hourly or flat rate service and click a book now button.
                We’ll take care of everything else.
              </p>
              <ul className="footer__info list-unstyled mt-35">
                <li>
                  <i className="fa-solid fa-location-dot"></i>13 Brittanica
                  Street Officer
                </li>
                <li>
                  <a href="tel:0403582550">
                    {" "}
                    <i className="fa-solid fa-phone"></i>0449 626 424
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 footer__col mt-30">
            <div className="footer__widget">
              <h3>Quick Links</h3>
              <ul className="footer__links list-unstyled">
                <li>
                  <a href="https://mommybearcleaning.com.au/about.html">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="https://mommybearcleaning.com.au/oneofcleaning.html">
                    Services
                  </a>
                </li>
                <li>
                  <a href="https://booking.mommybearcleaning.com.au/">
                    Book Online
                  </a>
                </li>
                <li>
                  <a href="https://mommybearcleaning.com.au/contact.html">
                    Contact Us
                  </a>
                </li>
                <li>
                  <a href="https://mommybearcleaning.com.au/faq.html">FAQ</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 footer__col mt-30">
            <div className="footer__widget">
              <h3>Popular Areas Served</h3>
              <ul className="footer__links list-unstyled">
                <li>
                  <a href="#!">Melbourne</a>
                </li>
                <li>
                  <a href="#!">Stonnington</a>
                </li>
                <li>
                  <a href="#!">Port phillip</a>
                </li>
                <li>
                  <a href="#!">Glen eira</a>
                </li>
                <li>
                  <a href="#!">Bayside</a>
                </li>
                <li>
                  <a href="#!">White horse</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 footer__col mt-30">
            <div className="footer__widget">
              <h3>Our Commitment</h3>
              <div className="footer__awards">
                Happiness Guarantee 200%. Book Now
              </div>
              <div className="footer__instagram d-flex">
                <a className="thumb" href="#!">
                  <img src={footerimg1} alt="" />
                </a>
                <a className="thumb" href="#!">
                  <img src={footerimg2} alt="" />
                </a>
              </div>
              <h4 className="f-text mt-35">
                <img src="assets/img/icon/fire.png" alt="" />
                <span>
                  Only Green-Eco Friendly Products<span></span>
                  <img
                    className="f-shape"
                    src="assets/img/shape/f_text_shape.png"
                    alt=""
                  />
                </span>
              </h4>
            </div>
          </div>
        </div>
        <div className="footer__bottom ul_li_between pt-15 pb-30" id="footer">
          <div className="footer__copyright mt-15">
            © Copyright ©2023{" "}
            <a href="#!" id="footer-a">
              Mommy Bear Cleaning Service
            </a>
            . All Rights Reserved Copyright
          </div>
          <ul className="footer__nav ul_li mt-15">
            <li>
              <a href="#!">Terms and conditions</a>
            </li>
            <li>
              <a href="#!"> Privacy policy</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
