import React from "react";
// import image from '../images/image1.jpg'
import styles from "./Header.module.css";

const Header = () => {
  return (
    <div className={styles.outerHeader} style={{ marginTop: "6.5em" }}>
      <div className={styles.text}>
        <h2>FRESH, HIGHLY EXPERIENCED CLEANING TEAMS ARE NOW AVAILABLE</h2>
        <p>
          Mommy Bear is a proud member of the Make a Difference Community. Every
          service we perform, we provide a meal to disadvantaged people here in
          Melbourne. Trusted, dependable cleaners. Every cleaner is screened so
          you can trust them to take care of your home
        </p>
      </div>
    </div>
  );
};

export default Header;
